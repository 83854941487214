import { message, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createservices } from "../../store/servicesSlice";
import { fetchDepartment } from "../../store/DepartmentSlice";
import { useParams } from "react-router-dom";
import { getByIdDoctor, updateDoctor } from "../../store/DoctorSlice";
import TextEditor from "../../Components/TextEditor/TextEditor";
export const EditDoctor = () => {
  const dispatch = useDispatch();

  const { data: Department, status } = useSelector(
    (state) => state?.Department
  );
  console.log("ddddddddddddd", Department);
  useEffect(() => {
    dispatch(fetchDepartment());
  }, []);

  const { data: Doctor, status2 } = useSelector((state) => state?.Doctor);
  console.log("ddddddddddddd", Doctor);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getByIdDoctor(id));
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [initialContent, setInitialContent] = useState("");
  const [content, setContent] = useState("");
  

  useEffect(() => {
    if (Doctor?.docDetails) {
      setInitialContent(Doctor.docDetails); // Populate existing content
      setContent(Doctor.docDetails)
    }
  }, [Doctor]);

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const HandleSubmit = (values) => {
    console.log(values);

    const params = new FormData();
    params.append("name", values.name.value);
    params.append("image", values.image.files[0]);
    params.append("designation", values.designation.value);
    params.append("linkFb", values.linkFb.value);
    params.append("linkTwitter", values.linkTwitter.value);
    params.append("linkLinkedIn", values.linkLinkedIn.value);
    params.append("week", values.week.value);
    params.append("departmentId", values.departmentId.value);
    params.append("startTime", values.startTime.value);
    params.append("endTime", values.endTime.value);
    params.append("docDetails", content);

    dispatch(updateDoctor(id, params));
  };

  return (
    <>
      <div ClassName="m-3">
        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            HandleSubmit(e.target);
          }}
        >
          <h3 className="text-start p-2">Edit Doctor</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6 pt-3">
                <label className="pb-2"> Name </label>
                <input
                  name="name"
                  placeholder="Name"
                  type="text"
                  className="form-control"
                  defaultValue={Doctor.name}
                />
              </div>
              <div className="col-md-6 pt-3">
                <div>
                  <label className="pb-2">Image</label>
                </div>
                <div className="position-relative">
                  <img
                    src={
                      selectedImage
                        ? selectedImage
                        : Doctor?.image
                        ? Doctor?.image
                        : "https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg"
                    }
                    alt="profile"
                    className="border-3 border-white shadow"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "150px",
                    }}
                  />
                  <input
                    type="file"
                    className="form-control rounded-pill position-absolute top-0"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "150px",
                      opacity: 0,
                    }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Start Time</label>
                <input
                  type="time"
                  placeholder="Email"
                  name="startTime"
                  className="form-control"
                  defaultValue={Doctor.startTime}
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">End Time</label>
                <input
                  type="time"
                  name="endTime"
                  placeholder="Enter Short Description"
                  className="form-control"
                  defaultValue={Doctor.endTime}
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="pb-2">Facebook Id</label>
                <input
                  type="text"
                  name="linkFb"
                  placeholder="Enter Short Description"
                  className="form-control"
                  defaultValue={Doctor.linkFb}
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Twitter Id</label>
                <input
                  type="text"
                  name="linkTwitter"
                  placeholder="Enter Short Description"
                  className="form-control"
                  defaultValue={Doctor.linkTwitter}
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Linkedin Id</label>
                <input
                  type="text"
                  name="linkLinkedIn"
                  placeholder="Enter Short Description"
                  className="form-control"
                  defaultValue={Doctor.linkLinkedIn}
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Select Week</label>
                <select
                  name="week"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select Week</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="3"></option>
                </select>
              </div>

              <div className="col-md-6  pt-3">
                <label className="pb-2">Department</label>
                <select
                  name="departmentId"
                  class="form-select"
                  aria-label="Default select example"
                >
                  {Department?.map((item, index) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Designation</label>
                <input
                  type="text"
                  name="designation"
                  placeholder="Enter Long Description"
                  className="form-control"
                  defaultValue={Doctor.designation}
                />
              </div>
              <div>
              <h3 className="text-center mt-4">Edit Doctor Details</h3>
              <TextEditor
                onContentChange={handleContentChange}
                initialContent={initialContent} // Pass existing content
                />
                </div>
            </div>

            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                  Update Doctor
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
