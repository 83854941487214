import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const DoctorSlice=createSlice({
    name: "Doctor",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setDoctor(state,action){
            state.data=action.payload
        },
        getDoctor(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})






export const {setDoctor,setStatus,getDoctor}=DoctorSlice.actions
export default DoctorSlice.reducer




// thunk

export function fetchDoctor(){
 
    return async function fetchDoctorThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
         

            await axios.get(`${api_url}doctor/get`,).then(
                (resp) => {
                    console.log(resp.data);
                    if(resp?.data?.status ==="ok"){
                        
                    }
                    dispatch(setDoctor(resp?.data?.data))
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createDoctor(param){
    
    return async function createDoctorThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        try{
            await axios.post(`${api_url}Doctor/create`,param).then(res=>{

                console.log(res.data)
                if (res?.data?.status == "ok") {
                    message.success("Doctor Created")  
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/doctor`;
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function DriverToggle(param){
    
    return async function createDoctorThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/toggle_captain_block.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("Toggle Update");
                   
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateDoctor(id,param){
    return async function updateDoctorThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}Doctor/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {

                    message.success(" Doctor Update Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/doctor`;
                }

            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            if(err?.response?.data?.error)
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteDoctor(id){
    return async function deleteDoctorThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}Doctor/delete/${id}`).then(res=>{
                if(res.data.status === "ok"){
                    message.success('Doctor deleted successfully!')
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdDoctor(id){

    return async function createDoctorThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))

        try{
            await axios.get(`${api_url}Doctor/get/${id}`,).then(res=>{
                console.log(res.data)
                dispatch(setDoctor(res?.data?.data))
                if (res?.data?.status == "ok") {


                    dispatch(setDoctor(res?.data?.data))
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
