import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const staffsSlice=createSlice({
    name: "staffs",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setstaffs(state,action){
            state.data=action.payload
        },
        getstaffs(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})






export const {setstaffs,setStatus,getstaffs}=staffsSlice.actions
export default staffsSlice.reducer




// thunk

export function fetchstaffs(){
 
    return async function fetchstaffsThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
         

            await axios.get(`${api_url}staff/get`,).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setstaffs(resp?.data?.data))
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createstaffs(param){
    
    return async function createstaffsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        try{
            await axios.post(`${api_url}staff/create`,param).then(res=>{

                console.log(res.data)
                if (res?.data?.status == "ok") {
                    message.success("staffs Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Staffs`;
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function loginstaff(param) {
    return async function createusersThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}staff/loginStaff`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success(res.data?.message)
                    localStorage.setItem('staffRegister', res.data?.data?.id)
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Dashboard`;
                }else{
                    message.error(res.data?.status)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatestaffs(id,param){
    return async function updatestaffsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}staff/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("staff updated successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Staffs`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletestaffs(id){
    return async function deletestaffsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}staff/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdstaffs(id){

    return async function createstaffsThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))

        try{
            await axios.get(`${api_url}staff/get/${id}`,).then(res=>{
                console.log(res.data)
                dispatch(setstaffs(res?.data?.data))
                if (res?.data?.status == "ok") {
                    dispatch(setstaffs(res?.data?.data))
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
