import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Components/Navbar';
import Sidebar from '../Components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../store/sidebarSlice';

const OutRoutes = () => {
    const dispatch = useDispatch();
    const isopen = useSelector((state) => state.sidebar.isOpen);
    
  const Toggle = ()=>{
     dispatch(toggleSidebar());
     document.getElementById('tbCard').style.width = isopen ?  '1036px':'1300px'
  }
    return (
        <>
            
            <div className=' container-fluid d-flex p-0 '>
                <div className='position-fixed' style={{zIndex:'999'}}>
                    <Sidebar width={isopen ? '0px' : '310px'} display={isopen ? 'none' : 'block'} />
                </div>
                <div className="container-fluid p-0" style={{marginLeft:isopen?'0px':'310px',width: `calc(100vw - ${isopen?"0vw":"310px"})`}}>
                    <div className="main-panel pt-0">   
                        <Navbar Toggle={Toggle} />
                        <Outlet/>
                    </div>
                </div>
            </div>



        </>
    )
}

export default OutRoutes
