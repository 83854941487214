import { message, Modal} from "antd";
import React, { useState,useEffect } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { fetchDepartment, getByIdDepartment, updateDepartment } from "../../store/DepartmentSlice";
import { useParams } from "react-router-dom";
import { createDepartment } from "../../store/DepartmentSlice";
import axios from "axios";
import { api_url } from "../../ApiUrl";
export const EditDepartment = () => {
 


  const [Department,setDepartment]=useState(null)

    const dispatch = useDispatch();



  
    console.log("ddddddddddddd", Department);
  
    const {id} = useParams();
  
    useEffect(()=>{
      axios.get(`${api_url}department/get/${id}`).then(res=>{
        setDepartment(res.data.data)
      })

    },[])



  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.name.value.length===0){

      message.error('Please enter your name')
    }
    

    else {
  

        const param = {
          'name':values.name.value
        }

      
      

      dispatch(updateDepartment(id,param))  
    }
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Edit Department</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Department Name </label>
                <input name="name" placeholder="Name" type="text" className="form-control"
                 defaultValue={Department?.name}
                />
              </div>
              
            </div>
           
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
