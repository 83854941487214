import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./TextEditor.css";

const TextEditor = ({ onContentChange, initialContent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (initialContent) {
      const blocksFromHtml = htmlToDraft(initialContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    const content = newEditorState.getCurrentContent();

    // Convert the editor content to HTML
    const htmlContent = draftToHtml(convertToRaw(content));

    // Pass the HTML content to the parent via the callback
    onContentChange(htmlContent);
  };

  return (
    <div className="mt-4">
      <div className="card">
        <div className="card-body">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            placeholder="Start typing here..."
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
          />
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
