import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const managementSlice=createSlice({
    name: "management",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setmanagement(state,action){
            state.data=action.payload
        },
        getmanagement(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setmanagement,setStatus,getmanagement}=managementSlice.actions
export default managementSlice.reducer




// thunk

export function fetchmanagement(){
    return async function fetchmanagementThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}management/get`).then(
                (resp) => {

                    dispatch(setmanagement(resp.data.data))
                })
                
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createmanagement(param){
    
    return async function createmanagementThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}management/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("management Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatemanagement(id,params){
    return async function updatemanagementThunk(dispatch, getState){
        
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}management/update/${id}`,params).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("management Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletemanagement(id){
    return async function deletemanagementThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}management/delete${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdmanagement(id){

    return async function getByIdmanagementThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}management/get/${id}`).then(
                (resp) => {
                    dispatch(getmanagement(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
