import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const sellerSlice=createSlice({
    name: "AllSellers",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setseller(state,action){
            state.data=action.payload
        },
        getseller(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setseller,setStatus,getseller}=sellerSlice.actions
export default sellerSlice.reducer




// thunk

export function fetchseller(){
 
    return async function fetchsellerThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
            const params = new FormData();

            params.append("__api_key__", "hi,-its-eevee. I can do wonderful things in api creation.");
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }
            await axios.post(`${api_url}/fetch_sellers.php`,params,config).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setseller(resp.data?.data?.sellers))
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createseller(param){
    
    return async function createsellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/add_shift.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("seller Created")
                    const navigate = useNavigate();
                    navigate('/')  
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateseller(id){
    return async function updatesellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/seller/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("seller Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteseller(id){
    return async function deletesellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/seller/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdseller(uid){
    return async function getByIdsellerThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{

            const params = new FormData()
        params.append(
            "__api_key__",
            "hi,-its-eevee. I can do wonderful things in api creation."
          );
          params.append("seller_uid",uid);
          console.log('ffffffffffffffffffff',uid);
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

            await axios.post(`${api_url}/fetch_seller.php`,params,config).then(
                (resp) => {
                    dispatch(getseller(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
