import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const DepartmentSlice=createSlice({
    name: "Department",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setDepartment(state,action){
            state.data=action.payload
        },
        getDepartment(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})






export const {setDepartment,setStatus,getDepartment}=DepartmentSlice.actions
export default DepartmentSlice.reducer




// thunk

export function fetchDepartment(){
 
    return async function fetchDepartmentThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
            


            
            await axios.get(`${api_url}Department/get`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setDepartment(resp?.data?.data))

                  if(resp?.data?.status==='ok'){

                  }
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createDepartment(param){
    
    return async function createDepartmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
       
        try{
            await axios.post(`${api_url}department/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("department Created  Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/department`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function DriverToggle(param){
    
    return async function createDepartmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/toggle_captain_block.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("Toggle Update");
                   
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateDepartment(id,param){
    return async function updateDepartmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}department/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("Department Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/department`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteDepartment(id){
    return async function deleteDepartmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}department/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdDepartment(param){
    return async function createDepartmentThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/fetch_captains.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
