import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import {updatenews } from "../../store/newsSlice";
import { getByIdnews } from "../../store/newsSlice";
import { useParams } from "react-router-dom";
export const EditNews = () => {
 

    const dispatch = useDispatch();

    const { data: news, status } = useSelector((state) => state?.news);
    console.log("ddddddddddddd", news);
  
  
  
    const {id} = useParams();
  
    useEffect(()=>{
       dispatch(getByIdnews(id))
    },[])


    
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };



  const HandleSubmit = (values)=>{
       console.log(values);



        const params = new FormData()

        params.append('title',values.title.value)
        params.append('image',values.image.files[0])
        params.append('description',values.description.value)

      dispatch(updatenews(id,params))  
    
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add News</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2"> Name </label>
                <input name="title" placeholder="Name" type="text" className="form-control"
                 defaultValue={news?.title}
                 />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Image</label>
                <div className='position-relative'>
                <img src={selectedImage?selectedImage:news?.image ? news?.image : 'https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg'} alt='profile' className='border-3 border-white shadow' style={{ width: '150px', height: '150px',borderRadius:'150px' }} />
                  <input
                    type="file"
                    className="form-control rounded-pill position-absolute top-0"
                    id="image"
                    style={{ width: '150px', height: '150px',borderRadius:'150px',  opacity: 0 }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              

              
              <div className="col-md-12">
                <label className="pb-2">Description</label>
                <textarea type="text" rows="6" name="description" placeholder="Enter Long Description" className="form-control"
                 defaultValue={news?.description}
                ></textarea>
              </div>
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add News</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
