import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const aboutSlice=createSlice({
    name: "about",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setabout(state,action){
            state.data=action.payload
        },
        getabout(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setabout,setStatus,getabout}=aboutSlice.actions
export default aboutSlice.reducer




// thunk

export function fetchabout(){
    return async function fetchaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}about/get`).then(
                (resp) => {
                    dispatch(setabout(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function aboutToggle(id){
    
    return async function createaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     

        try{
            await axios.put(`${api_url}about/toggle/${id}`).then(res=>{
                console.log(res.data)
                if (res?.data?.status == "ok") {
                    message.success(res.data.message);                  
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function createabout(param){
    return async function createaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/about/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("about Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/about Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateabout(id,param){
    return async function updateaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}about/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("about Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/about Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteabout(id){
    return async function deleteaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/about/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdabout(id){
    return async function getByIdaboutThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}about/get/${id}`).then(
                (resp) => {
                    console.log(resp.data,'=======<>>>')
                    dispatch(getabout(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
