import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";

import { fetchwebsites, getByIdwebsites, updatewebsites } from "../../store/websitesSlice";
export const AddWebsiteSetting = () => {
 

    const dispatch = useDispatch();

    const { data: websites, status } = useSelector((state) => state?.websites);
    console.log("ddddddddddddd", websites);




    
    
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  
  
  
    useEffect(()=>{
       dispatch(getByIdwebsites(1))
    },[])




  const HandleSubmit = (values)=>{
       console.log(values);
    


        const params = new FormData()
        params.append('logo',values.logo.files[0])
        params.append('videoLink',values.videoLink.value)

      dispatch(updatewebsites(1,params))  
    
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add Website Setting</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
             
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Logo</label>
                <div className='position-relative'>
                <img src={selectedImage?selectedImage:websites?.logo ? websites?.logo : 'https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg'} alt='profile' className='border-3 border-white shadow' style={{ width: '150px', height: '150px',borderRadius:'150px' }} />
                  <input
                    type="file"
                    className="form-control rounded-pill position-absolute top-0"
                    id="logo"
                    style={{ width: '150px', height: '150px',borderRadius:'150px',  opacity: 0 }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label className="pb-2">Video Link</label>
                <input type="text" name="videoLink" defaultValue={websites?.videoLink} placeholder="Short Description" className="form-control" />
              </div>
              
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add Setting</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
