import { message, Modal} from "antd";
import React, { useState ,useEffect} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { createslider } from "../../store/sliderSlice";
export const AddSliderSetting = () => {
 
    const dispatch = useDispatch();

    const { data: slider, status } = useSelector((state) => state?.slider);
    console.log("ddddddddddddd", slider);
  
  
 



  const HandleSubmit = (values)=>{
       console.log(values);
    


        const params = new FormData()
        params.append('title',values.title.value)
        params.append('image',values.image.files[0])
        params.append('description',values.description.value)

      dispatch(createslider(params))  
    
  }




  return (
    <>
      <div ClassName="m-3">
 
        <form className="" onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
        <h3 className="text-start p-2">Add Slider Setting</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Title</label>
                <input name="title" placeholder="Name" type="text" className="form-control"
             
                />
              </div>
              <div className="col-md-6">
                <label className="pb-2">Image</label>
                <input type="file" name="image" placeholder="Number" className="form-control"
                     
                />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
             
              
              <div className="col-md-12 pt-3">
                <label className="pb-2">Description</label>
                <textarea type="text" rows="6" name="description" placeholder="Enter Long Description" className="form-control" 
                     
                ></textarea>
              </div>
            </div>
          
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Add Setting</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
