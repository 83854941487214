import React, { useState, useEffect } from "react";
import { message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createDoctor } from "../../store/DoctorSlice";
import { fetchDepartment } from "../../store/DepartmentSlice";
import { createservices } from "../../store/servicesSlice";
import TextEditor from "../../Components/TextEditor/TextEditor";

export const AddDoctor = () => {
  const dispatch = useDispatch();

  const { data: Department, status } = useSelector(
    (state) => state?.Department
  );

  useEffect(() => {
    dispatch(fetchDepartment());
  }, []);
  
  const [content, setContent] = useState(""); // State for editor content
  const handleContentChange = (newContent) => {
    setContent(newContent); // Save content in the parent component's state
  };

  const HandleSubmit = (values) => {
    console.log(values);
    // console.log("Editor Content:", content);

    if (values.name.value.length === 0) {
      message.error("Please enter your name");
    } else if (values.image.value.length === 0) {
      message.error("Please enter your image");
    } else if (values.designation.value.length === 0) {
      message.error("Please enter your designation");
    } else if (values.linkFb.value.length === 0) {
      message.error("Please enter your linkFb");
    } else if (values.linkTwitter.value.length === 0) {
      message.error("Please enter your linkTwitter");
    } else if (values.linkLinkedIn.value.length === 0) {
      message.error("Please enter your linkLinkedIn");
    } else if (values.week.value.length === 0) {
      message.error("Please enter your week");
    } else if (values.departmentId.value.length === 0) {
      message.error("Please enter your start department");
    } else if (values.startTime.value.length === 0) {
      message.error("Please enter your start Time");
    } else if (values.endTime.value.length === 0) {
      message.error("Please enter your end Time");
    } else if (!content) {
      message.error("Please enter doctor details");
    } else {
      const params = new FormData();
      params.append("name", values.name.value);
      params.append("image", values.image.files[0]);
      params.append("designation", values.designation.value);
      params.append("linkFb", values.linkFb.value);
      params.append("linkTwitter", values.linkTwitter.value);
      params.append("linkLinkedIn", values.linkLinkedIn.value);
      params.append("week", values.week.value);
      params.append("departmentId", values.departmentId.value);
      params.append("startTime", values.startTime.value);
      params.append("endTime", values.endTime.value);
      params.append("doctorDetails", content);

      dispatch(createDoctor(params));
    }
  };

  return (
    <>
      <div ClassName="m-3">
        <form
          className=""
          onSubmit={(e) => {
            e.preventDefault();
            HandleSubmit(e.target);
          }}
        >
          <h3 className="text-start p-2">Add Doctor</h3>
          <div className="bg-white p-3 rounded-3 shadow m-4">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6 pt-3">
                <label className="pb-2"> Name </label>
                <input
                  name="name"
                  placeholder="Name"
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="pb-2">Image</label>
                <input
                  type="file"
                  name="image"
                  placeholder="Number"
                  className="form-control"
                />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label className="pb-2">Start Time</label>
                <input
                  type="time"
                  placeholder="Email"
                  name="startTime"
                  className="form-control"
                />
              </div>

              <div className="col-md-6">
                <label className="pb-2">End Time</label>
                <input
                  type="time"
                  name="endTime"
                  placeholder="Enter Short Description"
                  className="form-control"
                />
              </div>
              <div className="col-md-6 pt-3">
                <label className="pb-2">Facebook Id</label>
                <input
                  type="text"
                  name="linkFb"
                  placeholder="Enter Short Description"
                  className="form-control"
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Twitter Id</label>
                <input
                  type="text"
                  name="linkTwitter"
                  placeholder="Enter Short Description"
                  className="form-control"
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Linkedin Id</label>
                <input
                  type="text"
                  name="linkLinkedIn"
                  placeholder="Enter Short Description"
                  className="form-control"
                />
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Select Week</label>
                <select
                  name="week"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select Week</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                </select>
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Department</label>
                <select
                  name="departmentId"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select Department</option>
                  {Department?.map((item, index) => {
                    return (
                      <>
                        <option value={item?.id}>{item?.name}</option>
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 pt-3">
                <label className="pb-2">Designation</label>
                <input
                  type="text"
                  name="designation"
                  placeholder="Enter Long Description"
                  className="form-control"
                />
              </div>
              <div>
                <h3 className="text-center mt-4">Add Doctor Details</h3>
                <TextEditor onContentChange={handleContentChange} />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                  Add Doctor
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
