import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const rideCategorySlice=createSlice({
    name: "rideCategory",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setrideCategory(state,action){
            state.data=action.payload
        },
        getrideCategory(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setrideCategory,setStatus,getrideCategory}=rideCategorySlice.actions
export default rideCategorySlice.reducer




// thunk

export function fetchrideCategory(){

    return async function fetchrideCategoryThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{

            const params = new FormData()
            params.append(
                "__api_key__",
                "hi,-its-eevee. I can do wonderful things in api creation."
              );

            
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

            await axios.post(`${api_url}/fetch_ride_categories.php`,params,config).then(
                (resp) => {

                    dispatch(setrideCategory(resp?.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function rideCategoryToggle(param){
    
    return async function createrideCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/toggle_ride_category_enable_disable.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("Toggle Update");
                   
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function createrideCategory(params){
    return async function createrideCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

            await axios.post(`${api_url}/add_ride_category.php`,params,config).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("rideCategory Created")
                }else if (res.data?.data?.state?.data?.exceptions?.ride_category_with_this_name_already_exist ===true){

                    message.error("Ride Category Name Already Exist")
                }
                else {

                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updaterideCategory(id){
    return async function updaterideCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/rideCategory/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("rideCategory Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleterideCategory(id){
    return async function deleterideCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/rideCategory/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdrideCategory(id){
    return async function getByIdrideCategoryThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/rideCategory/${id}`).then(
                (resp) => {
                    dispatch(getrideCategory(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
