import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppointmentToggle,
  deleteappointment,
  fetchappointment,
} from "../store/appointmentSlice";
import { Modal } from "antd";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function AppointmentTodayTable() {

  const [newAppoint,setNewAppoint]=useState([])


  const dispatch = useDispatch();

  const { data: appointment, status } = useSelector(
    (state) => state?.appointment
  );
  console.log("ddddddddddddd", appointment);
  useEffect(() => {
    dispatch(fetchappointment());

    setNewAppoint(appointment);

    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
      }, 1000);
    });
  }, []);

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        dispatch(deleteappointment(id));
      },
    });
  };

  return (
    <>
      <div className="m-4">
        <div className=" container-fluid bg-white  rounded-3 shadow">
          <h3 className="text-start p-2">Todays Appointment</h3>
          <div className="col-md-12 text-end pt-4">
            <div className="table-responsive">
              <table class="table table-striped text-start" id='AllData'>
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Doctor Name</th>
                    <th scope="col">Appointment Time</th>
                    {/* <th scope="col">List Appointment</th> */}
                    <th scope="col">Customer Name</th>
                    <th scope="col">Appointment Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Block/Unblock</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {newAppoint && newAppoint?.map((item, index) => {
                      if(item?.status==='pending'){
                        return (
                          <>
                            <tr>
                              <td scope="row">{index + 1}</td>
                              <td>{item?.doctor?.name}</td>
                              <td>{item?.appointmentTime}</td>
                              <td>{item?.users?.username}</td>
                              <td>{item?.appointmentDate}</td>
                              <td>
                                {item?.status === "pending" ? (
                                  <span className="badge text-bg-warning">
                                    {item?.status}
                                  </span>
                                ) : (
                                  <span className="badge text-bg-success">
                                    {item?.status}
                                  </span>
                                )}
                              </td>
                              <td>
                                <label class="switch">
                                  {item?.block == true ? (
                                    <input
                                      type="checkbox"
                                      onClick={() => {
                                        dispatch(fetchappointment());
                                        const params = {
                                          'block':false,
                                          'status':'pending'
                                        }
                                        dispatch(AppointmentToggle(item?.id,params));
                                        dispatch(fetchappointment());
                                      }}
                                      defaultChecked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      onClick={() => {
                                        dispatch(fetchappointment());
                                        const params = {
                                          'block':true,
                                          'status':'approved'
                                        }
                                        dispatch(AppointmentToggle(item?.id,params));
                                        dispatch(fetchappointment());
                                      }}
                                    />
                                  )}
                                  <span class="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className=" d-flex justify-content-center">
                                  <Link to={`/edit_appointment/${item?.id}`}>
                                    <i
                                      style={{
                                        color: "blue",
                                        fontSize: "20px",
                                      }}
                                      class="bi bi-pencil-square"
                                    ></i>
                                  </Link>
                                  <i
                                    onClick={() => {
                                      onDeleteStudent(item?.id);
                                    }}
                                    class="bi bi-trash3 ms-3"
                                    style={{ color: "red", fontSize: "20px" }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }
                       
                      })
                    }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentTodayTable;
