import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const newsSlice=createSlice({
    name: "news",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setnews(state,action){
            state.data=action.payload
        },
        getnews(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setnews,setStatus,getnews}=newsSlice.actions
export default newsSlice.reducer




// thunk

export function fetchnews(){
    return async function fetchnewsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}news/get`).then(
                (resp) => {
                    dispatch(setnews(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createnews(param){
    return async function createnewsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}news/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("news created Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Latest News`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatenews(id,param){
    return async function updatenewsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}news/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("news Updated Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Latest News`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletenews(id){
    return async function deletenewsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}news/delete/${id}`).then(res=>{
                if(res.data.status === "ok"){
                  window.location.reload(true)
                  message.success('news deleted successfully!')
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdnews(id){
    return async function getByIdnewsThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}news/get/${id}`).then(
                (resp) => {
                    dispatch(getnews(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
