import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { getByIdcard, updateUniquecard, updatecard } from "../store/cardSlice";
import { message } from "antd";
function EditCardRegistraion() {
  const dispatch = useDispatch();
  $(document).ready(function () {
    setTimeout(function () {
      $("#AllData").DataTable({
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        bDestroy: true,
        dom: 'Bfrtip',
        buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });



  const { data: card, status } = useSelector((state) => state?.card);
  console.log("ddddddddddddd", card);



  const { id } = useParams();

  useEffect(() => {
    dispatch(getByIdcard(id))
  }, [])





  const handleUn=()=>{
    if(document.getElementById('uniqueNumber').value.length===0){
      message.error('Add unique number')
    }
    else{
      dispatch(updateUniquecard(id,{
        uniqueNumber: document.getElementById('uniqueNumber').value,
      }))
    }
    
  }

  const handle = (values) => {



    dispatch(
      updatecard(id, {

        name: values.name.value,
        mr: values.mr.value,
        relative: values.relative.value,
        age: values.age.value,
        date: values.date.value,
        address: values.address.value,
        phone: values.phone.value,
        

      })
    );

  };

  return (
    <>
      <div className="container py-4">



        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Add Unique Number
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <label>Unique Id</label>
                <input
                  type="number"
                  className="form-control"
                  name="uniqueNumber"
                  id="uniqueNumber"
                  defaultValue={card?.uniqueNumber}
                />
              </div>
              <div className="modal-footer">
                
                <button type="button" onClick={handleUn} className="btn btn-primary" data-bs-dismiss="modal">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>





        <div className="row">
          <div className="col-md-10  mx-auto">
            <div className="card p-3">
              <div className="text-center">
                <h2>BACH  CHRISTIAN HOSPITAL P.O QALAND ARABAD</h2>
                <h2>Phone: 0992-370007, 370108</h2>
              </div>

              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handle(e.target);
                  }}
                >
                  <div className=" row g-3">
                    <div className="col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="name"

                        defaultValue={card?.name}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>MR #</label>
                      <input type="text" className="form-control " name="mr"
                        defaultValue={card?.mr}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Relative:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name="relative"
                        defaultValue={card?.relative}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Age:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control "
                        name="age"
                        defaultValue={card?.age}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Date:</label>
                      <input type="date" className="form-control" name="date"
                        defaultValue={card?.date}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Address:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        defaultValue={card?.address}
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Phone#</label>
                      <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        defaultValue={card?.phone}
                      />
                    </div>

                    <div className="col-md-6">
                      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Add Unique Number
                      </button>

                    </div>

                    <div className="col-md-12">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default EditCardRegistraion;
