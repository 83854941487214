import axios from 'axios'
import { message } from "antd";
import {api_url} from '../ApiUrl';
const { createSlice } = require("@reduxjs/toolkit")

const STATUS = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const donationSlice = createSlice({
    name: "donation",
    initialState: {
        data: [],
        update:'',
        status: STATUS.IDLE,
    },

    reducers: {
        setdonation(state, action) {
            state.data = action.payload
        },
       setStatus(state, action) {
            state.status = action.payload
        }

    }

})

export const { setdonation, setStatus,getdonation } = donationSlice.actions

export default donationSlice.reducer

// thunk

export function fetchdonation() {
    return async function fetchdonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.put(`${api_url}donation/get`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setdonation(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function createdonation(param) {
    return async function createdonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/donation/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    
                    message.success("donation Created Successfully!")
                    
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function logindonation(param) {
    return async function createdonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/login/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success(res.data?.message)
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/dashboard`;
                    localStorage.setItem('userRegister', res.data?.data?.id)
                }else{
                    message.error(res.data?.status)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function updatedonation(id,param) {
    return async function updatedonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {
         
            await axios.put(`${api_url}donation/update/${id}`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("donation Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Donation%20History`;
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function deletedonation(id) {
    return async function deletedonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.delete(`${api_url}/donation/delete/${id}`).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    window.location.reload(true)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function getByIddonation(id) {
    return async function getByIddonationThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}donation/get/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setdonation(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}