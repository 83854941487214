import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const dealDaySlice=createSlice({
    name: "dealDay",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setdealDay(state,action){
            state.data=action.payload
        },
        getdealDay(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setdealDay,setStatus,getdealDay}=dealDaySlice.actions
export default dealDaySlice.reducer




// thunk

export function fetchdealDay(){
    return async function fetchdealDayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}/dealDay`).then(
                (resp) => {
                    dispatch(setdealDay(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createdealDay(param){
    return async function createdealDayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/dealDay/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("dealDay Created")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatedealDay(id){
    return async function updatedealDayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/dealDay/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status == "success") {
                    message.success("dealDay Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletedealDay(id){
    return async function deletedealDayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/dealDay/${id}`).then(res=>{
                if(res.data.status == "success"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIddealDay(id){
    return async function getByIddealDayThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/dealDay/${id}`).then(
                (resp) => {
                    dispatch(getdealDay(resp.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
