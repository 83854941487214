import { message, Modal} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createstaffs } from "../store/staffsSlice";
import { useParams } from "react-router-dom";
export const AddAppointmentToday = () => {
  const dispatch = useDispatch();

  const {id} = useParams();
   
  const [permssion, setPermissions] = useState([]);

  console.log(permssion);
  const perdata = (e) => {
    let temp = [...permssion, e.target.value];
    setPermissions(temp);
  };


  const HandleSubmit = (values)=>{
       console.log(values);
    if(values.name.value.length===0){

      message.error('Please enter your name')
    }
    else if (values.phone.value.length===0){
      message.error('Please enter your phone')
    }else if(values.email.value.length===0){
      message.error('Please enter your email')

    }else if (values.password.value.length===0){

      message.error('Please enter your password')

    }

    else {

      dispatch(createstaffs({
        'name':values.name.value,
        'phone':values.phone.value,
        'email':values.email.value,
        'permission':permssion,
         'password':values.password.value
        

      }))  
    }
  }




  return (
    <>
      <div ClassName="p-4 m-4">
        <h3 className="text-start p-2">Add Appointment Today</h3>
        <form onSubmit={(e) => {e.preventDefault(); HandleSubmit(e.target) }} >
          <div className="bg-white p-3 rounded-3 shadow">
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label>Doctor Name</label>
                <input name="name" type="text" className="form-control" />
              </div>
              <div className="col-md-6">
                <label>Customer Name</label>
                <input type="text" name="phone" className="form-control" />
              </div>
            </div>
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-6">
                <label>Starting Time</label>
                <input type="time" name="starting" className="form-control" />
              </div>

              <div className="col-md-6">
                <label>End Time</label>
                <input type="time" name="end_time" className="form-control" />
              </div>
              <div className="col-md-6 mt-4">
                <label>Description</label>
                <input type="text" name="description" className="form-control" />
              </div>
            </div>
            
            <div className=" row  p-3  d-flex justify-content-between">
              <div className="col-md-12">
                <button type='submit' className="btn btn-primary">Register</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
