import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")
 
const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const usersSlice=createSlice({
    name: "users",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        
        setusers(state,action){
            state.data=action.payload
        },
        getusers(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})






export const {setusers,setStatus,getusers}=usersSlice.actions
export default usersSlice.reducer




// thunk

export function fetchusers(){
 
    return async function fetchusersThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
           
            
            await axios.get(`${api_url}users/get`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setusers(resp?.data?.data))
                })

                dispatch(setStatus(STATUS.IDLE))

        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createusers(param){
    
    return async function createusersThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/add_users.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                    message.success("users Created")
                    const navigate = useNavigate();
                    navigate('/')  
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}


export function UsersToggle(id){
    
    return async function createusersThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     

        try{
            await axios.put(`${api_url}users/toggle/${id}`).then(res=>{
                console.log(res.data)
                if (res?.data?.status == "ok") {
                    message.success(res.data.message);                  
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateusers(id){
    return async function updateusersThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}/users/${id}`).then(res=>{
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("users Updated")
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteusers(id){
    return async function deleteusersThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/users/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdusers(param){
    return async function createusersThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
     
        
        
            const config = {

                headers: {

                    "Content-Type":"application/x-www-form-urlencoded"
                }
            }

        try{
            await axios.post(`${api_url}/fetch_captains.php`,param,config).then(res=>{
                console.log(res.data)
                if (res?.data?.state == "OK") {
                   
                } 
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
