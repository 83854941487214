import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import { fetchstaffs } from '../store/staffsSlice';
import { deletestaffs } from '../store/staffsSlice';
import { message,Modal } from 'antd';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { deletedonation, fetchdonation } from '../store/donationSlice';
function DonationHistoryTable() {

  

  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to Delete?",
      onOk: () => {
        
            dispatch(deletedonation(id))
        
  
        
      },
    });
  };
  const dispatch = useDispatch();

   const {data:donation,status} = useSelector((state) => state?.donation);
    console.log('ddddddddddddd',donation);
   useEffect(()=>{
     dispatch(fetchdonation());
     $(document).ready(function () {
      setTimeout(function () {
        $('#AllData').DataTable(
          {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            bDestroy: true,
            dom: 'Bfrtip',
            buttons: ['copy', 'csv', 'print']
          }
        );
      },
        1000
      );
    });
    
   },[])
  return (
    <>
<div className='p-4'>

<div className=' card container border-0 bg-white p-4 shadow'>

<div className="row pb-4 align-items-center" >
                <div className="col-md-6">
                <h3>Donation</h3>
                </div>
                
              </div>
<div className=' row  p-3  d-flex justify-content-between'>

{/* <div className='col-md-12 text-end'>
  <Link to="/Staffs" className="btn btn-danger">Add</Link>
</div> */}


    </div>
<table class="table" id='AllData'>
  <thead>
    <tr>
      <th scope="col">SL</th>
      <th scope="col">Full Name</th>
      <th scope="col"> Email</th>
      <th scope="col">Address</th>
      <th scope="col">Country</th>
      <th scope="col">Phone</th>
      <th scope="col">Amount</th>
      <th scope="col">Option</th>
    </tr>
  </thead>
  <tbody>
    {donation.length>0 ?donation?.map((item,index)=>{
      return (
        <>
           <tr>
      <th scope="row">{index+1}</th>
      <td>{item?.firstName + " " + item?.lastName}</td>
      <td>{item?.email}</td>
      <td>{item?.address}</td>
      <td>{item?.country}</td>
      <td>{item?.phone}</td>
      <td>{item?.amount}</td>
      {/* <td>Otto</td> */}
      {/* <td>@mdo</td> */}
      <td>
<div className=" d-flex justify-content-center">

 <Link to={`/edit_donation/${item?.id}`} ><i style={{color:'blue',fontSize:'20px'}}  class="bi bi-pencil-square"></i></Link>
<i

 onClick={() => {
  onDeleteStudent(item?.id);
}}
class="bi bi-trash3 ms-3" style={{color:'red',fontSize:'20px'}} ></i>
  
</div>

      </td>
    </tr>
        </>
      )
    }):''}
 
   
  </tbody>
</table>







</div>








</div>





    </>
  )
}

export default DonationHistoryTable