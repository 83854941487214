import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const LabSlice=createSlice({
    name: "Lab",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setLab(state,action){
            state.data=action.payload
        },
        getLab(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setLab,setStatus,getLab}=LabSlice.actions
export default LabSlice.reducer




// thunk

export function fetchLab(){
    return async function fetchLabThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}lab/get`).then(
                (resp) => {

                    dispatch(setLab(resp.data.data))
                })
                
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createLab(param){
    
    return async function createLabThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}lab/create`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("Lab Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Lab%20Reports%20History`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updateLab(id,params){
    return async function updateLabThunk(dispatch, getState){
        
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}lab/update/${id}`,params).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("Lab Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Lab%20Reports%20History`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deleteLab(id){
    return async function deleteLabThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}lab/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdLab(id){

    return async function getByIdLabThunk(dispatch, getState){

        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}lab/get/${id}`).then(
                (resp) => {
                    dispatch(getLab(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
