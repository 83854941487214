import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchcard, getByIdcard } from "../store/cardSlice";
import { useParams } from "react-router-dom";
const RegistrationCard = () => {
  const dispatch = useDispatch();

  const captureRef = useRef(null);

  

  const { data: card, status2 } = useSelector((state) => state?.card);
  console.log("ddddddddddddd", card);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getByIdcard(id));
  }, []);

  const printRef = useRef(null);

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload(true)
  };

  return (
    <div>
      <div className="container py-4 forms_cards">
        <div className="row">
          <div className="col-md-10  mx-auto">
            <div className="" ref={printRef}>
            <div className="card p-4" style={{border:'3px solid #000',borderRadius:'30px'}} >
              <div className="text-center">
                <h2 className="text-bold">
                  {" "}
                  BACH CHRISTIAN HOSPITAL P.O QALAND ARABAD DISTT ABBOTTABAD
                </h2>
                <h2>Phone: 0992-370007, 370108</h2>
              </div>

              <div>
              <div className="text-end">
                    <h6 className="" style={{fontWeight:'700'}}>ID CARD NUMBER:{card?.uniqueNumber}</h6>
                  </div>
                <form>
                  
                  <div className=" row" style={{display:'flex'}} >
                    <div className="col-6" style={{display:'flex',alignItems:'center'}} >
                      <label className="pt-4 text-bold"  style={{paddingTop:'14px',fontWeight:'600'}} >Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="name"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        defaultValue={card?.name}
                      />
                    </div>

                    <div className="col-6  d-flex align-items-center">
                      <label className="text-bold pt-4">MR#:</label>
                      <input
                        type="text"
                        className="form-control "
                        name="mr"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                      
                        defaultValue={card?.mr}
                      />
                    </div>

                    <div className="col-4  d-flex align-items-center">
                      <label className="text-bold pt-4 ">Relative:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name="relative"

                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        
                        defaultValue={card?.relative}
                      />
                    </div>

                    <div className="col-4 d-flex align-items-center">
                      <label className="text-bold pt-4">Age:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control "
                        name="age"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        defaultValue={card?.age}
                      />
                    </div>

                    <div className="col-md-4 d-flex align-items">
                      <label className="text-bold pt-4">Date:</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        defaultValue={card?.date}
                      />
                    </div>

                    <div className="col-md-12 d-flex align-items-center">
                      <label className="text-bold pt-4">Address:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        defaultValue={card?.address}
                      />
                    </div>

                    <div className="col-md-12 d-flex align-items-center">
                      <label className="text-bold pt-4">Phone:</label>
                      <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        style={{border:'0px',borderBottom:'1px solid black',borderRadius:'0px'}}
                        defaultValue={card?.phone}
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </form>
              </div>
            </div>
            </div>
           

            <div className="col-md-12 mt-4 text-end">
              <button
                type="submit"
                onClick={handlePrint}
                className="btn btn-primary"
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCard;
