import axios from 'axios'
import { api_url } from '../ApiUrl'
import { message } from "antd";
const {createSlice} = require("@reduxjs/toolkit")

const STATUS=Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const cardSlice=createSlice({
    name: "card",
    initialState:{
        data:[],
        status:STATUS.IDLE,
    },
    reducers:{
        setcard(state,action){
            state.data=action.payload
        },
        getcard(state,action){
            state.data=action.payload
        },
        setStatus(state,action){
            state.status=action.payload
        }
    }
})



export const {setcard,setStatus,getcard}=cardSlice.actions
export default cardSlice.reducer




// thunk

export function fetchcard(){
    return async function fetchcardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}card/get`).then(
                (resp) => {
                    dispatch(setcard(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function createcard(param){
    return async function createcardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.post(`${api_url}/card/`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("card Created")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/card Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function updatecard(id,param){
    return async function updatecardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}card/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("card Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/Registration%20Card%20List`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
export function CardToggle(id,param){

    console.log(id,param,'param id');
    return async function updatecardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}card/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("card Updated")
                    // const main_urls=window.location.origin
                    // window.location.href=`${main_urls}/#/card Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
export function updateUniquecard(id,param){
    return async function updatecardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.put(`${api_url}card/update/${id}`,param).then(res=>{
                console.log(res.data)
                if (res.data?.status == "ok") {
                    message.success("card Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/card Settings`;
                }
            })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function deletecard(id){
    return async function deletecardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.delete(`${api_url}card/delete/${id}`).then(res=>{
                if(res.data.status == "ok"){
                  window.location.reload(true)
                }
              })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}




export function getByIdcard(id){
    return async function getByIdcardThunk(dispatch, getState){
        dispatch(setStatus(STATUS.LOADING))
        try{
            await axios.get(`${api_url}card/get/${id}`).then(
                (resp) => {
                    dispatch(getcard(resp.data.data))
                })
                dispatch(setStatus(STATUS.IDLE))
        }catch(err){
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}
