import React from 'react'

function RegisterCardL() {
  return (
    <>
      <div ClassName="m-3">

        <h3 className='text-start p-2'>Registration Card List</h3>
        <div className='bg-white p-3 m-4 shadow'>

         
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label>Customer Name </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Cutomer Number </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Payment Status </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Date of Registration </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-6'>
              <label> Date of Issue </label>
              <input type="text" className='form-control' />
            </div>
            <div className='col-md-6'>
              <label> Card Number </label>
              <input type="text" className='form-control' />
            </div>


          </div>
          <div className=' row  p-3  d-flex justify-content-between'>

            <div className='col-md-12'>
              <buton className="btn btn-primary">Register</buton>
            </div>


          </div>

        </div>

      </div>





    </>
  )
}

export default RegisterCardL